.line-down {
  animation: 700ms anim-lineDown ease-out;
  visibility: hidden;
  animation-fill-mode: forwards;
}

@keyframes anim-lineDown {
  0% {
    opacity: 0;
    transform: translateY(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    visibility: visible;
  }
}

.text-vertical {
  writing-mode: vertical-rl;
}

.ant-tabs .ant-tabs-tab-btn {
  color: rgb(115 115 115);
}

.code-font {
  font-family: "Lucida Console", monospace;
}

li {
  list-style-type: none;
  position: relative;
  padding-left: 15px;
}

li:before {
  content: "\25BA \0020";
  font-size: 11px;
  position: absolute;
  top: 2px;
  left: -10px;
  color: var(--primary);
}

.code-button {
  font-family: "Lucida Console", monospace;
  padding: 1.5rem;
  display: flex;
  align-items: center;
}

.ant-anchor::before {
  border-inline-start: none !important;
  border: none !important;
}

.ant-anchor-ink {
  display: none !important;
}

.ant-anchor-link {
  padding-inline: 0 !important;
}

.blink {
  animation: opacity 1s ease-in-out infinite;
  opacity: 1;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-btn-link {
  color: var(--primary) !important;
}
