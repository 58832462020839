.run-animation path {
  stroke: var(--primary); /* border color*/
  stroke-width: 5px; /* width of a border */
  stroke-dasharray: 900; /* creating dashes */
  stroke-dashoffset: 900; /* where the dash of a stroke will begin */
  fill: none;
  -webkit-animation: dash 1.2s ease-in-out 1s 1 forwards;
  animation: dash 1.2s ease-in-out 1s forwards;
}

/* .run-animation path.i1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
} */

@keyframes dash {
  /* define aniamtion start point and end */
  from {
    stroke-dashoffset: 900;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.run-animation path {
  fill: none;
  stroke: var(--primary);
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 9px;
}

.logo path {
  fill: none;
  stroke: var(--primary);
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 9px;
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.remove svg {
  -webkit-transition: -webkit-transform 1s;
  -moz-transition: -moz-transform 1s;
  -o-transition: -o-transform 1s;
  transition: transform 1s;
  -webkit-animation: shrink 0.5s ease-in-out 1s 1 forwards;
  animation: shrink 0.5s ease-in-out 1s forwards;
  animation-delay: 2s;
}
